import crypto from 'crypto';

export const getIpAddress = async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      return data.ip; // Returns the client's IP address
    } catch (error) {
      console.error('Error fetching IP address:', error);
      return '0.0.0.0'; // Default to an invalid IP if the request fails
    }
  };