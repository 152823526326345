"use client";

import React, { useState, useRef, useEffect } from "react";
import Link from "next/link";
import Head from "next/head";
import Hero from "./Hero";
import FAQSection from "@/utils/FAQSection";
import SuccessStories from "@/utils/SuccessStories";
import { FAQSchema } from "@/schema/FAQSchema";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLoading } from "@/context/LoadingContext";
import SuccessPopup from "@/components/popup/SuccessPopup";
import Image from "next/image";
import world_map from "@/assets/images/world_map.svg";
import { phoneData } from "../lib/phoneData"; // Adjust the path based on your folder structure
import { useUserLocation } from "@/context/UserLocationContext";
import { getFacebookData } from "@/utils/getFacebookData";
import { hashData } from "@/utils/hashData";
import crypto from 'crypto';
import { trackEvent } from '@/utils/trackEvent';
import { getIpAddress } from "@/utils/getIpAddress";

type FormData = {
  name: string;
  email: string;
  phone: string;
  message: string;
};

interface DetailsSectionProps {
  title: string;
  subtitle: string;
  text: string;
}

const DetailsSection = ({ title, subtitle, text }: DetailsSectionProps) => {
  const cardRef = useRef<HTMLDivElement>(null);
  return (
    <section
      ref={cardRef}
      className="bg-white border border-slate-300 shadow-lg rounded-2xl p-6 mb-1 text-[var(--text-color)] transition-transform duration-300 ease-in-out transform hover:shadow-xl"
    >
      <div className="text-sm md:text-base mb-4">
        <div className="font-semibold gradient-new-text tracking-wide md:text-base text-sm uppercase mb-1">
          {title}
        </div>
        <h2 className="text-[var(--title-color)] leading-normal text-xl md:text-2xl font-normal mb-1 mt-0">
          {subtitle}
        </h2>
        <div>
          <p className="block my-2 text-[var(--text-color)]">{text}</p>
        </div>
      </div>
    </section>
  );
};

const Main = () => {
  const userLocation = useUserLocation();

  const cards = [
    {
      title: "B2B Contact Database",
      description:
        "Access readily available B2B contact information in one place.",
    },
    {
      title: "Sales Datasets",
      description:
        "Comprehensive sales data for precise targeting and strategic growth.",
    },
    {
      title: "Custom Datasets",
      description:
        "Bespoke company datasets designed to meet specific business objectives.",
    },
  ];

  const sections = [
    {
      id: "1",
      title: "Datasets Marketplace",
      subtitle: "Unlock Broad & Diverse B2B Datasets",
      text: "Extensive collection of B2B datasets with multiple industries and data types. Take advantage of our sales database to meet any business requirement. Be it data for market trends, competitor analysis, or more, our voluminous and diverse datasets have you covered.",
    },
    {
      id: "2",
      title: "Business Database",
      subtitle: "Reliable B2B Data Provider",
      text: "Leverage quality data with our regularly updated business database sourced from reputed websites. Data integrity is our priority. Make data-driven business decisions with your go-to data vendor backed by the most current and precise information.",
    },
    {
      id: "3",
      title: "Company & Contact Search",
      subtitle: "Comprehensive Contact Database",
      text: "Boost your outreach efforts with our extensive phone number database, database for emails, and other essential contact details. Our business datasets provide the essential information to connect with the right people and drive success.",
    },
    {
      id: "4",
      title: "Strategic Planning",
      subtitle: "Sales Datasets For Precision Targeting",
      text: "Sales-qualified business datasets for Facebook ads and Google ads. Streamline your digital marketing efforts with accurate targeting made possible by our extensive business database for sales. Find the most promising B2B leads, understand customer behavior, and refine your sales approach.",
    },
    {
      id: "5",
      title: "Data Accessibility",
      subtitle: "Flexible Data Formats For All Purposes",
      text: "Make use of a variety of adaptable formats of the BizDataset database provider to ensure seamless integration with your existing workflow. Whether you need CSV, JSON, XML, or custom formats, we deliver data in the way that best suits your needs.",
    },
    {
      id: "6",
      title: "Customer Helpdesk",
      subtitle: "Timely Customer Support",
      text: "Leverage our powerhouse of dedicated support team to assist you with any queries or concerns. Offering instant solutions and expert guidance, we ensure you have the support you need to make the most of our data services.",
    },
  ];

  const [faqItems, setFaqItems] = useState([
    {
      id: 1,
      question: "What is BizDataset?",
      answer:
        "BizDataset is a B2B data solutions platform offering reliable, accurate, and up-to-date data. We serve a wide range of industries, including but not limited to technology, healthcare, finance, retail, and manufacturing. Our business datasets are versatile and can be tailored to meet the needs of various sectors.",
      isOpen: false,
    },
    {
      id: 2,
      question: "Can I request custom datasets?",
      answer:
        "Absolutely! We provide custom data requests to meet your specific business requirements. Our team will work with you to deliver datasets tailored to your unique needs.",
      isOpen: false,
    },
    {
      id: 3,
      question: "How can your B2B data solutions improve my business?",
      answer:
        "Our B2B data solutions can help improve lead quality, increase customer retention, reduce sales cycle times, boost conversion rates, and enhance overall productivity.",
      isOpen: false,
    },
    {
      id: 4,
      question: "In what formats can I download the B2B data?",
      answer:
        "Our B2B data are available in various adaptable formats such as CSV, JSON, and XML. If you have custom requests on data formats, feel free to reach out to our team.",
      isOpen: false,
    },
    {
      id: 5,
      question: "How frequently are your datasets updated?",
      answer:
        "Our datasets are updated regularly with timestamps to ensure you have access to the most current and accurate information. The update frequency can vary depending on the dataset type.",
      isOpen: false,
    },
  ]);

  const buttonsData = [
    "Sales Datasets",
    "B2B Datasets",
    "Company Datasets",
    "Business Datasets",
    "Contact Datasets",
    "Business Leads",
    "B2B Database Provider",
    "Free Sample Datasets",
    "    B2B Datasets By Category",
  ];

  const buttonsdata1 = [
    "Export To CSV",
    "Search Data By Country",
    "Search Data By State",
    "Search Data By City",
    "Search By Category",
    "Datasets Marketplace",
    "B2B Database",
    "B2B Leads",
    "Emails Database",
    "Phone Numbers Database",
    "    B2B Datasets By Location",
  ];

  const steps = [
    {
      description: "Locate business leads by category or location.",
      icon: "1", // You can replace this with an actual SVG or image if needed
    },
    {
      description: "Download free sample B2B leads.",
      icon: "2",
    },
    {
      description: "Buy desired datasets. Export to CSV, JSON, and more.",
      icon: "3",
    },
    {
      description: "Contact your potential clients and drive results.",
      icon: "4",
    },
  ];

  const [isSearchBarVisible, setIsSearchBarVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Check if the user has scrolled down enough to show the search bar in the header
      const shouldBeVisible = window.scrollY > 100; // Adjust as needed

      // Update state only if there's a change to avoid unnecessary re-renders
      if (shouldBeVisible !== isSearchBarVisible) {
        setIsSearchBarVisible(shouldBeVisible);
      }
    };

    // Attach scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isSearchBarVisible]);
  // console.log(searchLocation);

  const faqSchema = FAQSchema(faqItems);

  const { isLoading, setIsLoading } = useLoading();
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [phone, setPhone] = useState("");
  const [phoneLength, setPhoneLength] = useState<number | null>(null);

  const [isEditing, setIsEditing] = useState(false);
  const [selectedCode, setSelectedCode] = useState("");

  useEffect(() => {
    if (userLocation?.country_calling_code) {
      const callingCode = userLocation.country_calling_code.replace("+", "");
      setSelectedCode(callingCode);

      const country = phoneData.find(
        (country) => country.phone === callingCode
      );
      if (country) {
        const maxPhoneLength = Array.isArray(country.phoneLength)
          ? Math.max(...country.phoneLength)
          : country.phoneLength;
        setPhoneLength(maxPhoneLength !== undefined ? maxPhoneLength : null);
      }
    }
  }, [userLocation]);

  const handleDropdownToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleCodeSelect = (code: React.SetStateAction<string>) => {
    setSelectedCode(code);
    setIsEditing(false);

    const selectedCountry = phoneData.find((country) => country.phone === code);
    if (selectedCountry) {
      const maxPhoneLength = Array.isArray(selectedCountry.phoneLength)
        ? Math.max(...selectedCountry.phoneLength)
        : selectedCountry.phoneLength;
      setPhoneLength(maxPhoneLength !== undefined ? maxPhoneLength : null);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>();


  
  const onSubmit: SubmitHandler<FormData> = async (data) => {
    const fullPhoneNumber = `+${selectedCode}${data.phone}`;
    // Get the current page URL
    const currentPageUrl = window.location.href;
  
    // The phone number and message
    const wappUrl = `https://wa.me/${fullPhoneNumber}?text=${encodeURIComponent(
      "Hi! I'm interested in your services"
    )}`;
  
    setIsLoading(true);
    const nameParts = data.name.split(' ');
    // Prepare event data for tracking
    const eventData = {
      event_name: "Contact",
      event_time: Math.floor(Date.now() / 1000),
      event_id: Math.floor(Date.now() / 1000),
      user_data: {
        fn: [hashData(nameParts[0] ?? "")],
        ln: [hashData(nameParts.slice(1).join(' ') ?? "")],
        em: [hashData(data.email ?? "")], 
        ph: [hashData(fullPhoneNumber ?? "")], 
        ct: [hashData(userLocation?.city ?? "")],
        st: [hashData(userLocation?.region ?? "")],
        country: [hashData(userLocation?.country_name ?? "")],
        zp: [hashData(userLocation?.postal ?? "")],
        client_ip_address: await getIpAddress(), 
        client_user_agent: navigator.userAgent,
        fbc: getFacebookData().fbc, 
        fbp: getFacebookData().fbp, 
        fb_login_id: getFacebookData().c_user,
      },
      custom_data: {
        currency: userLocation?.currency ?? "INR",
      },
      event_source_url: currentPageUrl, 
      action_source: "website",
    };
    
    try {
      // First, submit the contact form
      const response = await fetch(
        `${process.env.NEXT_PUBLIC_MAIN_API_URL}/user/contact-us`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            phone: fullPhoneNumber,
            message: `***Custom Dataset Request!*** ${currentPageUrl} *** ${wappUrl} ***  ${data.message}`,
            email: data.email,
            name: data.name,
          }),
        }
      );
  
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
  
      const result = await response.json();
      reset(); // Clear the form fields
      setSuccessMessage(
        `Request Received. We’re reviewing it and will be in touch soon.`
      ); // Set success message
  
      trackEvent(eventData);

  
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };
  



  

  return (
    <>
      <Head>
        <meta charSet="UTF-8" />
        <title>Reliable B2B Database Provider</title>

        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Organization",
              url: "https://www.bizdataset.com",
              sameAs: [
                "https://www.facebook.com/bizdataset/",
                "https://x.com/bizdataset",
                "https://www.instagram.com/bizdataset/",
                "https://www.youtube.com/@bizdataset",
                "https://www.bizdataset.com/",
                "https://www.linkedin.com/company/bizdataset/",
              ],
              logo: "https://bizdataset-report.s3.eu-north-1.amazonaws.com/asset/favicon-512x512.png",
              name: "BizDataset",
              description:
                "Your Source For Premium B2B Database. Search sales databases, emails, phone numbers, and other vital details – Reliable, up-to-date, and easily accessible.",
              email: "contact@bizdataset.com",
              telephone: "+91-93535-09364",
            }),
          }}
        />

        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(faqSchema) }}
        />

        <meta property="og:title" content="Business Datasets Provider" />
        <meta name="twitter:title" content="Reliable B2B Database Provider" />

        <meta
          name="description"
          content="Download free B2B datasets. Access business data with phone numbers, email addresses, sales datasets, and company datasets. Boost your lead quality, and conversion rates with BizDataset’s B2B datasets. Buy datasets now!"
        />
        <meta
          property="og:description"
          content="Download free B2B datasets. Access business data with phone numbers, email addresses, sales datasets, and company datasets. Boost your lead quality, and conversion rates with BizDataset’s B2B datasets. Buy datasets now!"
        />
        <meta
          name="twitter:description"
          content="Download free B2B datasets. Access business data with phone numbers, email addresses, sales datasets, and company datasets. Boost your lead quality, and conversion rates with BizDataset’s B2B datasets. Buy datasets now!"
        />

        <meta
          name="keywords"
          content="Business Datasets, Business Data, Business Database, B2B Datasets, B2B Data, Sales Database, Sales Datasets, Datasets Marketplace "
        />

        <link rel="canonical" href="https://www.bizdataset.com/" />
        <meta property="og:url" content="https://bizdataset.com/" />

        <meta
          property="og:image"
          content="https://bizdataset-report.s3.eu-north-1.amazonaws.com/asset/favicon-512x512.png"
        />
        <meta
          name="twitter:image"
          content="https://bizdataset-report.s3.eu-north-1.amazonaws.com/asset/favicon-512x512.png"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@bizdataset" />

        <meta name="robots" content="index, follow"></meta>
        {/* <link
          rel="preload"
          href="https://bizdataset-report.s3.eu-north-1.amazonaws.com/asset/world_map.svg"
          as="image"
        /> */}
      </Head>

      <div className="relative">
        <div className="relative overflow-x-hidden">
          {/* section1 */}
          <div>
            <Hero />
          </div>

          {/* section2 */}
          {/* <Suspense fallback={<div>Loading...</div>}>
        <MarqueeSection />
      </Suspense> */}

          {/* section3 */}
          <section className="pt-12 pb-16 ">
            <div className="box-border ml-auto mr-auto max-w-[1232px] pr-3 pl-3 w-[96%]">
              <div className="text-center text-[var(--title-color)] text-2xl lg:text-4xl font-semibold pt-10 mb-6">
                <div className=" mb-5 mb-xl-8">
                  <span className="gradient-new-text">5000+</span> Categories,{" "}
                  <span className="gradient-new-text">19M+</span> B2B Datasets
                </div>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 gap-4">
                {cards.map((card, index) => (
                  <div key={index} className="col-span-1 mb-4">
                    <div className="flex flex-col items-center bg-white bg-opacity-50 border rounded-lg shadow-lg p-8 relative border-[#f1f1f1] h-full w-full transition-transform transform  hover:shadow-xl">
                      <div className="mb-4 gradient-new-text font-semibold tracking-wide text-[18px]">
                        {card.title}
                      </div>
                      <div className="body mb-4 text-center font-light text-[18px] leading-6 tracking-wide flex-grow">
                        <p>{card.description}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>

          {/*step by step guit */}
          <div className="relative py-12 mb-4 ">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="text-2xl lg:text-4xl font-semibold text-center">
                Your Data, Just A{" "}
                <span className="gradient-new-text">Few Clicks Away</span>
              </div>
              <div className="text-lg text-center">
                Access business datasets across the world map without any
                hassle.
              </div>
              <div className="relative mt-10 flex justify-center flex-wrap gap-6">
                {/* Dashed lines */}
                <div className="absolute inset-0 w-[800px] ml-44 flex items-center justify-between px-4">
                  {/* {steps.map((_, index) => (
                    <div
                      key={index}
                      className={` -mt-[65px] w-full h-0 border-t border-dashed border-gray-300 ${
                        index < steps.length - 1 ? "border-r" : ""
                      }`}
                      style={{ borderTop: "2px dashed  gray", flex: 1 }}
                    />
                  ))} */}
                </div>

                <div className="relative flex justify-center flex-wrap gap-6">
                  {steps.map((step, index) => (
                    <div
                      key={index}
                      className="flex flex-col items-center w-60 relative z-10"
                    >
                      <div className="w-24 h-24 bg-white rounded-full shadow-md flex items-center justify-center text-4xl font-bold">
                        <span className="gradient-new-text">{step.icon}</span>
                      </div>
                      <p className="mt-4 text-gray-600 text-center max-w-[250px]">
                        {step.description}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* section keywords */}
          <section className="text-[var(--title-color)] tracking-wider ">
            <div className="max-w-screen-2xl mx-auto px-4">
              <div className="flex flex-col items-center justify-center">
                <div>
                  <div className="text-2xl lg:text-4xl font-semibold text-center tracking-wide">
                    Our{" "}
                    <span className="gradient-new-text">
                      B2B Data Solutions
                    </span>
                  </div>
                </div>

                <div className="w-full lg:w-[50%] py-8 space-y-4">
                  {/* First Row */}
                  <div className="relative overflow-hidden">
                    <div className="flex animate-marquee-right whitespace-nowrap">
                      {buttonsData
                        .concat(buttonsData)
                        .map((buttonLabel, index) => (
                          <div key={index} className="mx-2">
                            {buttonLabel === "Free Sample Datasets" ? (
                              <Link href="/b2b-list">
                                <div className="bg-white bg-opacity-50 text-sm text-[var(--title-color)] font-light tracking-wider py-1 px-4 border border-slate-300 rounded transition duration-300 ease-in-out transform hover:scale-105 hover:bg-[#E8F5FE]">
                                  {buttonLabel}
                                </div>
                              </Link>
                            ) : (
                              <button className="bg-white bg-opacity-50 text-sm text-[var(--title-color)] font-light tracking-wider py-1 px-4 border border-slate-300 rounded transition duration-300 ease-in-out transform hover:scale-105 hover:bg-[#E8F5FE]">
                                {buttonLabel}
                              </button>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>

                  {/* Second Row */}
                  <div className="relative overflow-hidden">
                    <div className="flex animate-marquee-left whitespace-nowrap">
                      {buttonsdata1
                        .concat(buttonsdata1) // Concatenates the list to create a looping effect
                        .map((buttonLabel, index) => {
                          // Set the link only for "B2B Leads" and "B2B Database"
                          const isB2B =
                            buttonLabel === "B2B Leads" ||
                            buttonLabel === "B2B Database" || buttonLabel === "Free Sample Datasets" ||
                            buttonLabel === "Search Data By Country" ||
                            buttonLabel === "Search Data By State" ||
                            buttonLabel === "Search Data By City";

                          return isB2B ? (
                            <Link key={index} href="/b2b-list">
                              <div>
                                <button className="bg-white bg-opacity-50 text-sm text-[var(--title-color)] font-light tracking-wider py-1 px-4 border border-slate-300 rounded transition duration-300 ease-in-out transform hover:scale-105 hover:bg-[#E8F5FE] mx-2">
                                  {buttonLabel}
                                </button>
                              </div>
                            </Link>
                          ) : (
                            <button
                              key={index}
                              className="bg-white bg-opacity-50 text-sm text-[var(--title-color)] font-light tracking-wider py-1 px-4 border border-slate-300 rounded transition duration-300 ease-in-out transform hover:scale-105 hover:bg-[#E8F5FE] mx-2"
                            >
                              {buttonLabel}
                            </button>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* section4 */}
          <div className="max-w-screen-xl mx-auto mt-16 mb-8 px-4 md:px-6 lg:px-8 ">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {sections.map((section) => (
                <DetailsSection
                  key={section.id}
                  title={section.title}
                  subtitle={section.subtitle}
                  text={section.text}
                />
              ))}
            </div>
          </div>

          {/* section5 */}
          <section className="text-[var(--title-color)] text-center tracking-wide p-4 lg:p-10 ">
            <div>
              <div className="text-2xl lg:text-4xl font-semibold">
                Tailored{" "}
                <span className="gradient-new-text">Business Datasets</span> To
                Suit Your Requirements
              </div>
            </div>

            <div className="flex flex-col lg:flex-row items-center justify-center mt-8">
              <div className="mb-3 flex flex-col lg:flex-row gap-4 bg-white bg-opacity-70 lg:gap-40 shadow-lg p-4 lg:p-8 rounded-lg items-center lg:px-20">
                <ul className="grid grid-cols-1 gap-2 text-[14px] sm:text-[16px] lg:text-lg text-[var(--text-color)]">
                  <li className="flex items-center">
                    <svg
                      className="w-4 h-4 mr-2 text-green-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    Trustworthy B2B Data Vendor
                  </li>
                  <li className="flex items-center">
                    <svg
                      className="w-4 h-4 mr-2 text-green-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    Automated Data Extraction
                  </li>
                  <li className="flex items-center">
                    <svg
                      className="w-4 h-4 mr-2 text-green-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    No-Code Web Scraping
                  </li>
                  <li className="flex items-center">
                    <svg
                      className="w-4 h-4 mr-2 text-green-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    Latest B2B Data
                  </li>
                </ul>

                <div>
                  <div className="mt-5 lg:mt-0">
                    <Link
                      href="#request-custom-dataset"
                      onClick={() => setIsOpen(true)}
                    >
                      <button className="flex items-center text-[var(--blue-color)] hover:text-white hover:bg-[var(--blue-color)] border border-[var(--blue-color)] bg-white rounded-lg text-[14px] sm:text-[16px] py-2 px-4 transition duration-300 ease-in-out">
                        Request Custom Datasets!
                      </button>
                    </Link>
                  </div>
                  {isOpen && (
                    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-20  backdrop-blur-sm z-10">
                      <div className="bg-white rounded-lg flex flex-col p-8 w-[350px] md:w-[800px] max-h-[800px] mx-auto relative">
                        {" "}
                        {/* Added relative positioning */}
                        {/* Cross button positioned at the top right corner */}
                        <button
                          type="button"
                          onClick={() => setIsOpen(false)}
                          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700" // Positioning the button
                        >
                          &times; {/* Cross button */}
                        </button>
                        <div className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6 text-center">
                          Request{" "}
                          <span className="gradient-new-text">
                            Custom Dataset!
                          </span>
                        </div>
                        <div className="md:flex">
                          <div className="w-full md:w-1/2 flex justify-center items-center mb-4 md:mb-0">
                            <Image
                              src={world_map}
                              alt="imageAlt"
                              className="rounded-lg w-full md:w-[360px]"
                            />
                          </div>

                          {/* Form Section */}
                          <div className="w-full md:w-1/2 pl-4">
                            {" "}
                            {/* Added padding to the left for spacing */}
                            <form onSubmit={handleSubmit(onSubmit)}>
                              <div className="mb-4">
                                <input
                                  type="text"
                                  id="name"
                                  {...register("name", { required: true })}
                                  placeholder="Name" // Added placeholder
                                  className="border  border-gray-300 rounded-lg w-full p-2"
                                />
                              </div>
                              <div className="mb-4">
                                <input
                                  type="email"
                                  id="email"
                                  {...register("email", {
                                    required: "Email is required", // Custom error message for required
                                    pattern: {
                                      value:
                                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, // Regular expression for valid email
                                      message: "Invalid email format.", // Custom error message for invalid email format
                                    },
                                  })}
                                  placeholder="Email" // Added placeholder
                                  className="border border-gray-300 rounded-lg w-full p-2"
                                />
                                {errors.email && ( // Display error message if validation fails
                                  <p className="text-gray-500 text-xs">
                                    {errors.email.message}
                                  </p>
                                )}
                              </div>

                              <div className="relative mb-4 sm:mb-6 flex items-center">
                                {/* Display current country code and edit button */}
                                <span
                                  className="inline-block px-4 py-2 border h-[40px] w-auto mx-auto border-gray-300 rounded-l-md text-gray-500 cursor-pointer whitespace-nowrap"
                                  onClick={handleDropdownToggle}
                                >
                                  +{selectedCode}
                                  <span className="ml-2 text-gray-300">
                                    {isEditing ? "▲" : "▼"}
                                  </span>
                                  {/* Arrow indicator */}
                                </span>

                                {/* Phone input field */}
                                <input
                                  type="tel"
                                  {...register("phone", { required: true })}
                                  id="phone"
                                  name="phone"
                                  value={phone}
                                  onChange={(e) => {
                                    const newValue = e.target.value.replace(
                                      /\D/g,
                                      ""
                                    ); // Remove non-numeric characters
                                    setPhone(newValue);

                                  }}
                                  className="block bg-transparent w-full  rounded-r-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                                  placeholder="Phone number"
                                  required
                                  maxLength={phoneLength ?? undefined}
                                />
                                {/* Dropdown menu */}
                                {isEditing && (
                                  <div className="absolute top-full left-0 mt-2 w-70 border border-gray-300 bg-white rounded-md shadow-lg max-h-60 overflow-y-auto z-10">
                                    <ul>
                                      {phoneData.map(({ phone, label }) => (
                                        <li
                                          key={phone}
                                          className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                          onClick={() =>
                                            handleCodeSelect(phone)
                                          }
                                        >
                                          {label} - {phone}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                              </div>
                              {errors.phone && ( // Display error message if validation fails
                                <p className="text-gray-500 text-xs">
                                  {errors.phone.message}
                                </p>
                              )}

                              <div className="mb-4">
                                <textarea
                                  id="message"
                                  {...register("message", {
                                    required: true,
                                    validate: (value) =>
                                      /^[a-zA-Z0-9\s.,!?]*$/.test(value) ||
                                      "Only letters, numbers, and basic punctuation are allowed.",
                                  })}
                                  placeholder="Message" // Added placeholder
                                  className="border  border-gray-300 rounded-lg w-full p-2"
                                />
                                {errors.message && (
                                  <p className="text-gray-500 text-xs">
                                    {errors.message.message}
                                  </p>
                                )}
                              </div>
                              <div className="flex justify-center">
                                <button
                                  type="submit"
                                  className="border rounded-lg py-2 px-4 bg-[var(--blue-color)] text-white hover:bg-blue-600"
                                  disabled={isLoading} // Disable button while loading
                                >
                                  {isLoading ? "Submitting..." : "Submit"}
                                </button>
                              </div>
                            </form>
                            {successMessage && (
                              <SuccessPopup
                                message={`Request Received. We’re reviewing it and will be in touch soon.`}
                                onClose={() => setSuccessMessage(null)} // Change 'onSubmit' to 'onClose'
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>

          {/* section6 */}
          <div className="">
            <SuccessStories />
          </div>

          {/* section8 */}

          <section className="text-[var(--title-color)] text-center tracking-wide p-4 lg:p-10 max-w-screen-xl mx-auto ">
            <div>
              <div className="mb-3 flex flex-col gap-4 lg:gap-10 shadow-lg p-4 lg:p-8 bg-white bg-opacity-70 rounded-lg items-center ">
                <div>
                  <div className="text-2xl lg:text-4xl font-semibold">
                    <span className="gradient-new-text">
                      {" "}
                      Readily Available B2B Datasets
                    </span>{" "}
                    For Your Sales Team
                  </div>
                </div>
                <div className="mt-5 lg:mt-0">
                  <Link href={"/b2b-list"}>
                    <button  className="flex items-center text-[var(--blue-color)] hover:text-white hover:bg-[var(--blue-color)] border border-[var(--blue-color)] bg-white rounded-lg text-[14px] sm:text-[16px] py-2 px-4 transition duration-300 ease-in-out">
                      Explore Datasets
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </section>

          {/* section9 faq
          <div className=" mx-auto p-6 flex flex-col items-center justify-center mt-8">
            <div>
              <h2 className="mb-7 text-center text-[var(--title-color)] text-2xl font-semibold tracking-tight md:text-4xl">
                Datasets <span className="gradient-new-text">FAQs</span>
              </h2>
            </div>
            {faqItems.map((item) => (
              <div key={item.id} className="mb-6 max-w-[800px] w-full mx-auto">
                <div
                  className="flex items-center justify-between bg-white rounded-lg  pl-3 pr-2 py-3  text-[var(--title-color)] font-bold cursor-pointer"
                  onClick={() => toggle(item.id)}
                >
                  <div className="flex-1">{item.question}</div>
                  <span
                    className="h-6 w-6 flex items-center justify-center text-teal-500 cursor-pointer"
                    onClick={() =>
                      setItem((prevItem) => ({
                        ...prevItem,
                        isOpen: !prevItem.isOpen,
                      }))
                    }
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="#0070f0"
                      xmlns="http://www.w3.org/2000/svg"
                      className={`transition-transform ${
                        item.isOpen ? "rotate-180" : "rotate-0"
                      }`}
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071Z"
                        fill="#0070f0"
                      />
                    </svg>
                  </span>
                </div>
                {item.isOpen && (
                  <div className="p-3 text-[var(--text-color)] -mt-4  rounded-b-lg bg-white  ">
                    {item.answer}
                  </div>
                )}
              </div>
            ))}
          </div> */}

          <div className="">
            {/* Other content of your page */}
            <FAQSection faqItems={faqItems} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
